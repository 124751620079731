import { useEffect } from "react";
import "./style.scss"
const YouTube = () => {
    let isTrue = false;
    useEffect(() => {
        if (isTrue === false) {
            isTrue = true;
            const scriptDiv2 = document.createElement('script');
            scriptDiv2.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-MH29QND');`;
            document.head.appendChild(scriptDiv2);

            const noscriptDiv = document.createElement('noscript');
            noscriptDiv.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MH29QND"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
            document.body.appendChild(noscriptDiv)

        }
        document.title = 'Sino-bus 帶你走進中文世界';
    }, []);

    const go = () => {
        let url = "https://wa.me/8617788052878?text=%E5%92%A8%E8%A9%A2%E8%AA%B2%E7%A8%8B";
        window.open(url, "_blank");
    }

    return <div className="youTube" onClick={() => { go() }}>
        <div className="pc" >
            <img className="youtubePc" src="../../../../assets/youtubePc.png" alt="" />
        </div>
        <div className="mob">
            <img className="youtubeMob" src="../../../../assets/youtubeMob1.png" alt="" />
        </div>
    </div>
}
export default YouTube;