import logo from './logo.svg';
import './App.css';
import routes from './router';
import { useRoutes } from "react-router-dom"
import { useEffect, useState } from "react"
import "antd/dist/antd"
import ChatCom from './components/ChatCom';
function App() {
  const element = useRoutes(routes)
  useEffect(() => {
  }, [])
  return (
    <div className="App">
      {element}
      <ChatCom />
    </div>

  );
}

export default App;
