import { useEffect } from "react";
import "./style.scss"
const Singapore = () => {
    let isTrue = false;
    useEffect(() => {
        if (isTrue === false) {
            isTrue = true;
            //         let headHtml = `
            //         <script async src="https://www.googletagmanager.com/gtag/js?id=AW-438384628"></script>
            //         <script>
            //     (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            //     new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            //     j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            //     'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            //     })(window,document,'script','dataLayer','GTM-MH29QND');
            //     </script>
            //     <script>
            //     window.dataLayer = window.dataLayer || [];
            //     function gtag(){dataLayer.push(arguments);}
            //     gtag('js', new Date());
            //     gtag('config', 'AW-438384628');
            //    </script>
            //     `
            //         let bodyHtml = `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MH29QND"
            //     height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`
            //         let head = document.querySelector("head");
            //         let body = document.querySelector("body");
            //         head.innerHTML += headHtml;
            //         body.innerHTML += bodyHtml;
            const scriptDiv1 = document.createElement('script');
            scriptDiv1.src = 'https://www.googletagmanager.com/gtag/js?id=AW-438384628';
            const scriptDiv2 = document.createElement('script');
            scriptDiv2.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-MH29QND');`;
            const scriptDiv3 = document.createElement('script');
            scriptDiv3.innerHTML = `  window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-438384628');`;
            document.head.appendChild(scriptDiv3);
            document.head.appendChild(scriptDiv2);
            document.head.appendChild(scriptDiv1);
            const noscriptDiv = document.createElement('noscript');
            noscriptDiv.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MH29QND"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
            document.body.appendChild(noscriptDiv)

        }
        document.title = 'Online Chinese Tutoring Courses for Singapore - Sinobus';
    }, [])

    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', 'AW-438384628');
    const gtag_report_conversion = (url) => {
        var callback = function () {
            if (typeof (url) != 'undefined') {
                window.location = url;
            }
        };
        gtag('event', 'conversion', {
            'send_to': 'AW-438384628/9z6gCPfPz94YEPTvhNEB',
            'value': 1.0,
            'currency': 'USD',
            'event_callback': callback
        });
        return false;
    }
    return <div className="singapore">
        <a href='https://wa.me/8618165329059?text=%E5%8D%8E%E6%96%87%E5%92%A8%E8%AF%A2'>
            <img className="chatSg" onClick={() => { gtag_report_conversion('https://wa.me/8618165329059?text=%E5%8D%8E%E6%96%87%E5%92%A8%E8%AF%A2') }} src="../assets/whatsapp.png" alt="" />
        </a>
        <img className="imgDf" src="../assets/sg-page.png" alt="" />
        <img className="imgPc" src="../assets/sg-page-pc.png" alt="" />
    </div>
}
export default Singapore;