import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getHomeListAPI, systemConfigList } from "../../api";
import { fetchCount } from "../counterAPI";
// 创建一个异步 action creator
export const fetchHomePageData = createAsyncThunk(
    'counter/fetchHomePageData',
    async (_, thunkAPI) => {
        try {

            const response = await systemConfigList();
            console.log("response", response);
            return response.data; // 返回从接口获取的数据
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message); // 返回错误信息
        }
    }
);

export const counterSlice = createSlice({
    name: "counter",
    initialState: {
        value: 0,
        status: '',
        SetWebData: null,
        error: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchHomePageData.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchHomePageData.fulfilled, (state, action) => {
                state.status = 'succeeded';
                console.log('action-----------------', action)
                state.SetWebData = action.payload;
            })
            .addCase(fetchHomePageData.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export default counterSlice.reducer;