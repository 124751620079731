import './style.scss'
import { Pagination } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import { SoundOutlined } from '@ant-design/icons';
import { getBlogsListAPI } from "../../api";
import { getTimeFun } from "../../utils/data/time.js"
const BlogCom = ({ data, blogLists }) => {
    const nav = useNavigate();
    const [num, setNum] = useState(1);
    const [blogList, setBlogList] = useState(blogLists.rows)
    const [total, settTotal] = useState(blogLists.total)
    const [pageSize, setPageSize] = useState(10)
    console.log('=====++++++=', blogLists);
    const onChange = (page, pageSize) => {
        setNum(page)
        setPageSize(pageSize)
        getBlogsListAPI({ area: data, pageNum: page, pageSize: pageSize }).then((res) => {
            setBlogList(res.rows);
            settTotal(res.total)
        })
    };
    const blogInfo = (item) => {
        // blogsId
        nav('/' + item.link, { state: { param: item } });
    };
    useEffect(() => {

    }, [num, pageSize])
    return <div className='blog'>
        {
            blogList?.map((item, index) => {
                return <div className='blogItem' key={index} onClick={() => { blogInfo(item) }} >
                    <div className='title'><SoundOutlined twoToneColor="#eb2f96" /> {item.title}</div>
                    <div className='time'>{getTimeFun(item.createTime, 3)}</div>
                </div>
            })
        }
        <Pagination showSizeChanger onChange={onChange} defaultCurrent={1} total={total} style={{ height: '32px', lineHeight: '32px', textAlign: 'center' }}
        />
    </div>
}
export default BlogCom;