import axios from 'axios'
import { message } from 'antd';
import { Spin } from 'antd';
import React from 'react';
import './loading.scss';
import { createRoot } from 'react-dom/client';
import { LoadingOutlined } from '@ant-design/icons';
// 默认域名 
axios.defaults.baseURL = "/api";
// 配置请求头
axios.defaults.headers["Content-Type"] = "application/json";
// 响应时间
axios.defaults.timeout = 20000;
//请求拦截器
axios.interceptors.request.use(
    config => {
        // getUser();//获取用户信息
        showLoading();//显示加载动画
        if (user) {
            // 设置统一的请求header
            config.headers.Authorization = "Bearer " + "11" //授权(每次请求把token带给后台)
        }
        // config.headers.Authorization = "Bearer eyJhbGciOiJIUzUxMiJ9.eyJ1c2VyX2lkIjoxLCJ1c2VyX2tleSI6ImMzZDRlODFkLWU1YTctNDcxYi05MWU2LTI1MGYxM2YzODIyNiIsInVzZXJuYW1lIjoiYWRtaW4ifQ.fopBHJkkum1DexCq7ICSA3sV5LcjfpoZLEkTHTzafipnxXz0J42_d1wVl6a3JE0VTs3pp6H9EYntVtjhKJ2xdQ" //授权(每次请求把token带给后台)
        config.headers.platform = user ? user.platform : 8 //后台需要的参数
        return config;
    },
    error => {
        hideLoading();//关闭加载动画
        return Promise.reject(error);
    }
);

//响应拦截器
axios.interceptors.response.use(
    response => {
        hideLoading();//关闭加载动画
        if (response.data.returnCode === '0014') { // 登录失效
            localStorage.clear(); // 清除缓存
            message.success({
                content: '您的登录已经失效，请重新登录',
                duration: 2
            });
            setTimeout(() => {
                //让用户从新回到登录页面
                window._ROUTER_.push('/login');//router是在顶级入口app.js文件定义了window._ROUTER_ = this.props.history;
            }, 2000)
        }
        return response;
    },
    error => {
        hideLoading();//关闭加载动画
        return Promise.resolve(error.response);
    }
);

// 处理请求返回的数据
function checkStatus(response) {
    return new Promise((resolve, reject) => {
        if (response && (response.status === 200 || response.status === 304 || response.status === 400)) {
            resolve(response.data);

        } else {
            message.error({
                content: '网络异常，请检查网络连接是否正常！',
                duration: 2
            });
        }
    });
}

export const post = (url, params) => {
    return axios({
        method: "post",
        url,
        data: params
    }).then(response => {
        return checkStatus(response);
    });
};
export const get = (url, params) => {
    return axios({
        method: "get",
        url,
        params,
    }).then(response => {
        return checkStatus(response);
    });
}

// 获取缓存中的用户信息, 这是接口返回的信息。
var user;
function getUser() {
    if (localStorage.getItem('userInfo')) {
        user = JSON.parse(localStorage.getItem('userInfo'));
    }
}

// 显示加载动画
function showLoading() {
    const container = document.createElement('div');
    container.setAttribute('id', 'loading')
    document.body.appendChild(container);
    const root = createRoot(container); // createRoot(container!) if you use TypeScript
    root.render(<Spin indicator={<LoadingOutlined style={{ fontSize: '24', }} spin />} />,);
}
// 隐藏加载动画
function hideLoading() {
    document.body.removeChild(document.getElementById('loading'))

}