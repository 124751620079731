import { useState, useEffect } from 'react';
import './style.scss'
import { Carousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

const HkCom = () => {
    const contentStyle = {
        margin: 0,
        height: '160px',
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
    };
    const [titleUl, setTitleUl] = useState();
    let isTrue = false
    useEffect(() => {
        setTitleUl(document.querySelector('.titleUl'));
        if (isTrue === false) {
            isTrue = true;
            let headHtml = `
            <script>
            (function (w, d, s, l, i) {
                w[l] = w[l] || []; w[l].push({
                    'gtm.start':
                        new Date().getTime(), event: 'gtm.js'
                }); var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                        'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', 'GTM-MH29QND');
        </script>
        <noscript>
            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MH29QND" height="0" width="0"
                style="display:none;visibility:hidden">
            </iframe>
        </noscript>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-030BVYLGM8"></script>
        <script>
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('config', 'G-030BVYLGM8');
        </script>
        <meta name="description" content="港澳中文補習，選擇用心的Sinobus">
        <meta property="og:image"
            content="http://sino-java.oss-cn-shenzhen.aliyuncs.com/2024-04-28/%E6%96%B0%E5%A4%B4%E5%83%8F1714268640679.png?Expires=4867868640&OSSAccessKeyId=LTAI5t7JyJTMUGbf7rtfejnV&Signature=c6O5bXLKVFs8RMbNnmFsNYFlwYo%3D" />
        `

            let head = document.querySelector("head");
            head.innerHTML += headHtml;
            document.title = '中文科，線上補';
        }
    }, []);
    const go = (className) => {
        var height = document.querySelector(className).offsetTop;
        console.log("高度", height)
        // scrollTo(0, height);
        window.scrollTo({
            top: height - 40,
            behavior: 'smooth' // 平滑滚动效果
        });
    }
    const isShowHandle = () => {
        console.log('titleUl', titleUl);
        if (titleUl)
            if (titleUl.classList.length > 1) {
                titleUl.classList.remove('show');
            } else {
                titleUl.classList.add('show');
            }
    }
    const moreClick = () => {
        // console.log("go");
        window.location.href = "https://wa.me/85257484737";
    };
    let serveList = [
        { img: "../assets/hkinfo/s1.png", name: "班主任", info: `了解學員需求 <br/> 按需匹配試聽課 <br/> 跟蹤課程反饋 <br/> 制定適配的課程計劃` },
        { img: "../assets/hkinfo/s2.png", name: "授課老師", info: "沉浸式遊戲化互動教學<br/>1對1作業批改<br/>及時有效地跟蹤學員學習情況<br/>與家長反饋溝通" },
        { img: "../assets/hkinfo/s3.png", name: "輔導老師", info: "學員課程安排<br/>學情反饋<br/>答疑解惑<br/>課程內容拓展" },
        { img: "../assets/hkinfo/s4.png", name: "質檢老師", info: "課程內容優化<br/>保證定制課程質量<br/>遠程設備調試<br/>監控上堂情況" }
    ];
    const serveCom = (name) => {
        return serveList?.map((item, index) => {
            return <div className={`serveItem ${name}`} key={index} >
                <img className={`img${index + 1}`} src={item.img} alt="" />
                <div className={name == 'serveMob' ? 'serveRight' : ''}>
                    {name == 'servePc' ? <div className="line"></div> : ""}
                    <div className="name">{item.name}</div>
                    {name == 'serveMob' ? <div className="line"></div> : ""}
                    <div className="serveInfo"  >
                        {item.info.split('<br/>').map((line, lineIndex) => (
                            <p key={lineIndex}>
                                {line}
                                {lineIndex > 0 && <br />}
                            </p>

                        ))}</div>
                </div>
            </div>
        })
    }
    let list = [
        {
            img: "../assets/hkinfo/class111.jpg", title: "一對一閱讀習作專項課<br/>(P.3-S.3)",
            info1: "▶在讀學員專項提分率高達100%；<br/>▶教授審題「獨家秘笈」，快速定位答案區域；<br/>▶資深名師授課，從根本解決閱讀習作「疑難雜症」；<br/>▶高分寫作範文講解，幫助建立好詞好句素材庫。", info2: ''
        },
        { img: "../assets/hkinfo/class22.jpg", title: "一對一呈分試中文輔導課<br/>(P.4-P.6)", info1: "▶在讀學員第一志願升讀率90%；<br/>▶講解與操卷並重，提高「準確率」減少做題時間；<br/>▶聆聽、閱讀、寫作、說話能力全方面level up；<br/>▶短期高效提分，名師教授答題技巧與「解題秘訣」", info2: '' },
        { img: "../assets/hkinfo/class33.jpg", title: "一對一校本輔導課<br/>(P.1-P.6)", info1: "▶學員來自全港超250所學校，校内平均提高15分+；<br/>▶「活學」「我愛」「現代」「啟思」系列及自製校本；<br/>▶涵蓋多個必考課題，助力學生補底拔尖；<br/>▶家長滿意度高達95%。", info2: '' },
        { img: "../assets/hkinfo/class44.jpg", title: "一對一幼稚園中文輔導課<br/>(K.1-K.3)", info1: "▶識字造句、看圖說故事，建立中文學習基礎；<br/>▶升小面試輔導，提升孩子的自信，加強記憶力、觀察力、圖片描述和故事排序能力；<br/>▶在讀學員升小成績斐然，成功升入SPCC、喇沙、男拔、女拔、王錦輝、SFA、KTS、TSL等名校", info2: '' },]
    const courseCom = () => {
        return list.map((item, index) => {
            return <div className="courseItem" key={index}>
                <div className="courseImg">
                    <img src={item.img} alt="" />
                </div>
                <div className="courseText">
                    <div className="courseTexttitle">
                        {item.title.split('<br/>').map((line, lineIndex) => (
                            <p key={lineIndex}>
                                {line}
                                {lineIndex > 0 && <br />}
                            </p>

                        ))}</div>
                    <div className="courseTextinfo1">
                        {item.info1.split('<br/>').map((line, lineIndex) => (
                            <p key={lineIndex}>
                                {line}
                                {lineIndex > 0 && <br />}
                            </p>

                        ))}</div>
                    <button className="learn-more" onClick={() => { moreClick() }}>立即咨詢</button>
                </div></div>
        })
    };
    return (
        <div className="hkPages">
            <div className="headers">
                <div className="titleNav">
                    <div className="titleLogo"> <img src="../assets/logo.png" alt="" />
                    </div>
                    <div className="titleUl">
                        <div className="titleItem" onClick={() => { go('.one') }}>課程介紹</div>
                        <div className="titleItem" onClick={() => { go('.tow') }}>課程特色</div>
                        <div className="titleItem" onClick={() => { go('.three') }}>師資介紹</div>
                        <div className="titleItem" onClick={() => { go('.four') }}>配套服務</div>
                        <div className="titleItem" onClick={() => { go('.five') }}>關於Sinobus</div>
                        <div className="titleItem" onClick={() => { go('.six') }}>公司地址</div>
                    </div>
                    <div className="titleUlMob" onClick={() => { isShowHandle() }}>
                        <img src="../assets/hkinfo/menu.png" alt="" />
                    </div>
                </div>
            </div>
            <div className="banner">
                <img className="bannerPc" src="../assets/hkinfo/bannerNew.png" alt="" />
                <img onClick={() => { moreClick() }} className="bannerPc foot1" src="../assets/hkinfo/foot1.png" alt="" />
                <img className="bannerMob" src="../assets/hkinfo/hkBannerMob3.png" alt="" />
                <a href='https://wa.me/85257484737'><img className="btnImg"
                    src="../assets/hkinfo/freeBtn.png" alt="" /></a>
                <div className="ImgMob" >
                    <a href='https://wa.me/85257484737'>
                        <p className="imgMob-text">免 費 預 約 試 堂</p>
                    </a>
                </div>
            </div>
            <div className="seniorityDiv one">
                <div className="title">課程介紹</div>
                <div className="courseInfo">
                    <div className="courseDiv">
                        {courseCom()}
                    </div>
                </div>
                <div>
                </div>
                <div className="seniority">
                    <div className="seniorityItem">
                        <img src="../assets/hkinfo/T1.png" alt="" />
                        <div>
                            <div className="seniorityText1">500+</div>
                            <div className="seniorityText2">港澳授課師資</div>
                        </div>
                    </div>
                    <div className="seniorityItem">
                        <img src="../assets/hkinfo/T2.png" alt="" />
                        <div>
                            <div className="seniorityText1">100%</div>
                            <div className="seniorityText2">教師本科以上學歷</div>
                        </div>
                    </div>
                    <div className="seniorityItem">
                        <img src="../assets/hkinfo/T3.png" alt="" />
                        <div>
                            <div className="seniorityText1">200,000+</div>
                            <div className="seniorityText2">累計完課頻次</div>
                        </div>
                    </div>
                    <div className="seniorityItem">
                        <img src="../assets/hkinfo/T4.png" alt="" />
                        <div>
                            <div className="seniorityText1">100,000+</div>
                            <div className="seniorityText2">品牌粉絲</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bannerImg classTs">
                <div className="title title2 tow">課程特色</div>
                <img src="../assets/hkinfo/feature22.png" className="addressPc" alt="" />
                <img src="../assets/hkinfo/featureMob2.png" className="addressMob" alt="" />
            </div>
            <div className="appointment"> <button onClick={() => { moreClick() }} className="learn-more">立即咨詢</button></div>
            <div className="title three">老師介紹</div>
            <Carousel arrows infinite={false} prevArrow={<LeftOutlined />} nextArrow={<RightOutlined />}>
                {/* <div  >
                    <img className="theacher-img" src="../assets/hkinfo/t1.png" alt="" />
                </div> */}
                <div >
                    <img className="theacher-img" src="../assets/hkinfo/t222.png" alt="" />
                </div>
                <div >
                    <img className="theacher-img" src="../assets/hkinfo/t333.png" alt="" />
                </div>
                <div >
                    <img className="theacher-img" src="../assets/hkinfo/t444.png" alt="" />
                </div>
                <div >
                    <img className="theacher-img" src="../assets/hkinfo/t55.png" alt="" />
                </div>
            </Carousel>

            {/* <div className="swiper">
                <div className="swiper-wrapper">
                    <div className="swiper-slide">
                        <img className="theacher-img" src="../assets/hkinfo/t1.png" alt="" />
                    </div>
                    <div className="swiper-slide">
                        <img className="theacher-img" src="../assets/hkinfo/t222.png" alt="" />
                    </div>
                    <div className="swiper-slide">
                        <img className="theacher-img" src="../assets/hkinfo/t333.png" alt="" />
                    </div>
                    <div className="swiper-slide">
                        <img className="theacher-img" src="../assets/hkinfo/t444.png" alt="" />
                    </div>
                    <div className="swiper-slide">
                        <img className="theacher-img" src="../assets/hkinfo/t55.png" alt="" />
                    </div>

                    <div className="swiper-button-prev"></div>
                    <div className="swiper-button-next"></div>
                </div>
            </div> */}
            <div className="seniorityDiv serveDiv" style={{ padding: 0, marginTop: '40px' }}>
                <div className="title four">配套服務</div>
                <div className="serve">
                    {
                        serveCom('servePc')
                    }
                    {
                        serveCom('serveMob')
                    }
                </div>
                <br />
                <div className="appointment"> <button onClick={() => { moreClick() }} className="learn-more">立即咨詢</button></div>
                <div className="title five">關於Sinobus</div>
                <div className="about">
                    Sinobus由漢和文化科技有限公司創辦，總部位於中國·深圳，在中國·香港、中國·中山、中國·西安、美國·洛杉磯、新加坡均設有分公司。公司專注于為K1-K3、P1-P6、S1-S3港澳學童及4-16歲海外華裔兒童提供系統的中文/數學培訓服務。定製化的課程內容，全心全意的配套服務，幫助孩子實現高效學習。
                </div>
            </div>
            <div className="title title2 address six">公司地址</div>
            <div className="bannerImg" style={{ backgroundColor: 'white' }}>
                <img src="../assets/hkinfo/addressPc.png" className="addressPc" alt="" />
                {/* <img src="../assets/hkinfo/addressMob2.png" className="addressMob" alt="" /> */}
                <div className="footer-hk">
                    <div className="footer-item">
                        <p className="footer-title">中國深圳辦公中心 | 全球總部</p>
                        <p className="footer-p">地址： 中國深圳市南山區沙河街道文昌街社區香山裏花園</p>
                        <p className="footer-p">電話：19925317349</p>
                    </div>
                    <div className="footer-item">
                        <p className="footer-title">漢和文化科技（深圳）有限公司香港分公司
                            <br /> 中國香港辦公中心
                        </p>
                        <p className="footer-p">地址：RM023，9/F，BLK GKWAI SHING IND BLDG(STAGE2)24-46TAI LIN PAI RD，KWAICHUNG NT</p>
                        <p className="footer-p">電話：852 68567158</p>
                    </div>
                    <div className="footer-item">
                        <p className="footer-title">漢和文化科技（深圳）有限公司西安分公司<br /> 中國西安辦公中心 | 教研基地</p>
                        <p className="footer-p">地址： 中國西安市雁塔區曲江池南路金地湖城大境</p>
                        <p className="footer-p">電話：02989123322</p>
                    </div>
                    <div className="footer-item">
                        <p className="footer-title">漢和文化科技（深圳）有限公司中山分公司</p>
                        <p className="footer-p">地址： 中國中山市港口鎮興港中路136號</p>
                        <p className="footer-p">電話：076088512186</p>
                    </div>
                    <div className="footer-item">
                        <p className="footer-title">美國洛杉矶辦公中心 | 運營基地</p>
                        {/* <p className="footer-p">Los Angeles, USA Limited</p> */}
                        <p className="footer-p">地址：75 egret, Irvine,California, 92618</p>
                        <p className="footer-p">電話：+1 2403026131</p>
                    </div>
                    <div className="footer-item">
                        <p className="footer-title">漢和文化科技有限公司新加坡分公司</p>
                        <p className="footer-p">地址：73 UPPER PAYA LEBAB ROAD#06-01C CENTRO BIANCO SINGAPORE(534818)</p>
                        <p className="footer-p">電話：+65 84294388 </p>
                    </div>
                </div>

                <br />
                <div className="footerDiv">
                    <div className="footerItem item1">
                        <div className="footerLogo">
                            <img src="../assets/hkinfo/logo.png" alt="" />
                        </div>
                        <div className="chatText">
                            聯繫我們
                        </div>
                        <div className="addressMob">
                            <img style={{ width: '150px', marginBottom: '10px' }}
                                src="../assets/hkinfo/code.png" alt="" />
                        </div>
                        <div className="chatType">
                            <a href="https://wa.me/85257484737"> <img
                                src="../assets/hkinfo/whatsapp.png" alt="" /></a>
                            <a href="https://www.facebook.com/profile.php?id=100082337253874"> <img
                                src="../assets/hkinfo/fb.png" alt="" /></a>
                            <a href="https://www.xiaohongshu.com/user/profile/6143a42f000000000201f6d7?xhsshare=WeixinSession&appuid=6143a42f000000000201f6d7&apptime=1713836968">
                                <img src="../assets/hkinfo/redbook.png" alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="footerItem item2">
                        <ul className="classList">
                            <li className="classList-one">sinobus課程</li>
                            <li>港澳普通話培訓課程</li>
                            <li>港澳K1-P6中文補習</li>
                            <li>港澳K1-P6數學補習</li>
                            <li>港澳S1-S3中文補習</li>
                        </ul>
                    </div>
                    <div className="footerItem item3">
                        <img src="../assets/hkinfo/code.png" alt="" />
                    </div>
                </div>
                <div className="chatDiv">
                    <div>
                        <a href='https://wa.me/85257484737'> <img className="chatIcon"
                            src="../assets/hkinfo/WhatsApp.png" alt="" /></a>
                    </div>
                    <div>
                        <a href='https://m.me/61554822001745'>
                            <div className="suspend">免費預約試堂</div>
                        </a>
                    </div>
                </div>
                <div id="floaty-window" style={{ display: 'none' }}></div>
            </div>


        </div>
    )
}
export default HkCom;