export const getTimeFun = (timeData, num) => {
    if (timeData) {
        let time = new Date(timeData);
        let year = time.getFullYear();
        let month = time.getMonth() + 1;
        let day = time.getDate();
        let hours = time.getHours();
        let minutes = time.getMinutes();
        let seconds = time.getSeconds();
        hours = (hours < 10 ? "0" : "") + hours;
        minutes = (minutes < 10 ? "0" : "") + minutes;
        seconds = (seconds < 10 ? "0" : "") + seconds;
        let timeString1 = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
        let timeString2 = hours + ":" + minutes + ":" + seconds;
        let timeString3 = year + "-" + month + "-" + day;
        let timeString4 = hours + ":" + minutes
        if (num === 1) {
            return timeString1;
        } else if (num === 2) {
            return timeString2;
        } else if (num === 3) {
            return timeString3;
        } else if (num === 4) {
            return timeString4
        }
    } else {
        return ' '
    }
}