import "./style.scss"
import { useSelector, } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react";
const ChatCom = () => {
    const [whatsUrl, setWhatsUrl] = useState();
    const [fbUrl, setFbUrl] = useState();
    const Setdata = useSelector(state => state.SetWeb.SetWebData);
    useEffect(() => {
        console.log("Setdata", Setdata);
        if (Setdata) {
            console.log("Setdata1", Setdata);
            Setdata.map((item, index) => {
                if (item.licence == '1') {
                    if (item.softwareType === '2' && item.setupType === '1') {
                        setFbUrl(item.chatUrl)
                    }
                    if (item.softwareType == '3') {
                        console.log("item3", item);
                        setWhatsUrl(item.chatUrl)
                    }
                }
            })
        }
    }, [Setdata])
    const nav = useNavigate();
    const chatUs = (url) => {
        // console.log("点击了", num);
        // nav(utl);
        window.open(url);
    };
    return (
        <div className="chat">
            {
                whatsUrl ? <img className="whatsIcon" src="../assets/whatsapp.png" alt="" onClick={() => { chatUs(whatsUrl) }} /> : ""
            }
            {
                fbUrl ? <img className="fbIcon" src="../assets/Facebook-Messenger-Logo.png" alt="" onClick={() => { chatUs(fbUrl) }} /> : ""
            }

        </div>
    )
}
export default ChatCom;