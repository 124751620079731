import { post, get } from "../utils/request.js";
export const getHomeListAPI = (data) => {
    return get('/website/index/list', data);
}
//   "websiteType": "", 网站类型(0香港,1新加坡)
//   "courseType": "",科目类型(0中文,1数学)
//   "area": ""所属地区(0香港中文,2香港数学,3新加坡中文,4新加坡数学)
export const getPageListAPI = (query) => {
    return get('/website/index/pageList', query);
}
// 联系我们页面
export const getContactList = () => {
    return get(`/website/index/contactList`)
}
// 表单提交
export const subFrom = (data) => {
    return post(`/website/index/sendEmail`, data)
}
// 查询博客   index/blogsList?area=0     get请求     (area0香港中文,2香港数学,3新加坡中文,4新加坡数学)
export const getBlogsListAPI = (data) => {
    return get(`/website/index/blogsAllList`, data);

}
// 博客评论 index/addBlogComment
export const addBlogAPI = (data) => {
    return post(`/website/index/addBlogComment`, data);

}

// 获取评论 index/blogsCommentList?blogsId=311329823869894656   
export const getCommentListAPI = (blogsId) => {
    return get('/website/index/blogsCommentList?blogsId=' + blogsId)
}
// 获取博客的url列表 index/getBlogContent?link=https://www.baidu.com.cn
export const getBlogContent = (link) => {
    return get('/website/index/getBlogContent?link=' + link)
}
// 获取配置码
export const systemConfigList = () => {
    return get('/website/index/systemConfigList')
}