import "./style.scss"
import { useDispatch, useSelector } from "react-redux"
import { incrementByAmount } from "../../store/Header";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Carousel } from 'antd';
import { useState } from "react";
import { fetchHomePageData } from "../../store/SetWeb";
import { getHomeListAPI } from "../../api";

const HomePage = () => {
    const dispatch = useDispatch();
    const count = useSelector(state => state.SetWeb.SetWebData);
    // console.log("count+++++++++++++++++++++++++", count);
    const [pageList, setPageList] = useState([]);

    useEffect(() => {
        dispatch(incrementByAmount(0))
        dispatch(fetchHomePageData());
        getHomeListAPI().then((res) => {
            setPageList(res.data);
        });
    }, [])

    const nav = useNavigate();
    const switchPage = (index) => {
        if (index === 0) {
            nav("/hongkong");
        } else if (index === 1) {
            nav("/singapore");
        } else if (index === 2) {

        }

    };
    // let pageList = HomePageData.pageList;
    const homePageItem = (item, index) => {
        return (
            <div key={index} className={['module', index === 0 ? 'hongkongBox' : '', index === 1 ? 'singaporeBox' : '', index === 2 ? 'otherBox' : ''].join(" ")} onClick={() => { switchPage(index) }}>
                <div style={{ backgroundImage: `url(${item.photo})` }} className='moduleitem'>
                    <div className="signLogo">
                        <img src={item.profile}></img>
                    </div>
                    <div className="countryName">{item.name}</div>
                    <div className="line"></div>
                    <div className="classTitle">{item.title}</div>
                    <div className="classInfo">{item.courceIntroduction}</div>
                </div>
            </div>
        )
    };
    return (
        <div className="homePage">
            <div className="pc">
                {pageList?.map((item, index) => {
                    return homePageItem(item, index);
                })}
            </div>

            <div className="mob">
                <Carousel effect="fade">
                    {pageList?.map((item, index) => {

                        return homePageItem(item, index);
                    })}
                </Carousel>
            </div>
        </div>
    )
}
export default HomePage;