import { useEffect } from "react";
import "./style.scss"
const Singapore = () => {
    let isTrue = false;
    useEffect(() => {
        if (isTrue === false) {
            isTrue = true;
            const scriptDiv2 = document.createElement('script');
            scriptDiv2.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-MH29QND');`;
            document.head.appendChild(scriptDiv2);
            const noscriptDiv = document.createElement('noscript');
            noscriptDiv.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MH29QND"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
            document.body.appendChild(noscriptDiv)

        }
        document.title = '港澳中文補習，幼小銜接，閱讀寫作專項';
    }, [])

    return <div className="hkonline">
        <img src="../assets/AdImg/bagF.png" alt="" />
        <div className="icon_boxF">
            <a href="https://wa.me/8617788052878?text=%E4%BA%86%E8%A7%A3%E7%9B%B8%E9%97%9C%E8%AA%B2%E7%A8%8B">
                <img src="../assets/whatsapp.png" alt="" className="mbs_icon" />
            </a>
        </div>
    </div>
}
export default Singapore;